import React, { forwardRef, InputHTMLAttributes } from "react";

import ErrorMessage from "@/common/components/ErrorMessage";
import FormField from "@/common/components/FormField";
import { useFormFieldStyles } from "@/common/components/FormField.styles";

interface EmailFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  errorMessage?: string;
  name: string;
}

const Wrapper = (
  { id, label, required, placeholder, errorMessage, name, ...props }: EmailFieldProps,
  ref: any,
) => {
  const { classes } = useFormFieldStyles();

  return (
    <FormField id={id} label={label} required={required}>
      <input
        ref={ref}
        {...props}
        name={name}
        data-cy={name}
        className={`${classes.formInput} ${errorMessage ? classes.inputErrorState : ""}`}
        id={id}
        type="email"
        placeholder={placeholder}
        required={required}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} name={name} />}
    </FormField>
  );
};

export const EmailField = forwardRef(Wrapper);
