import { useDisable2FaMutation, useEnable2FaMutation } from "@/graphql/types";

export const useTwoFactorStore = () => {
  const [enable2Fa, { loading: enable2FaLoading, data: enable2FaData }] = useEnable2FaMutation();
  const [disable2Fa, { loading: disable2FaLoading, data: disable2FaData }] =
    useDisable2FaMutation();
  return {
    enable2Fa,
    enable2FaLoading,
    enable2FaData,
    disable2Fa,
    disable2FaLoading,
    disable2FaData,
  };
};
