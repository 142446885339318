import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  dialogRoot: {
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  title: {
    color: theme.mode.text.heading,
    fontSize: "1.0625rem",
    fontWeight: "800",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(4, 0, 2, 0),
    margin: theme.spacing(0, 3),
  },
  headerCloseIcon: {
    height: "auto",
  },
  content: {
    padding: "0 24px",
    "& .description": {
      fontSize: "0.9375rem",
      color: theme.mode.text.formLabel,
      marginBottom: 24,
      display: "block",
    },
  },
  label: {
    color: theme.mode.text.formLabel,
    fontSize: "0.9375rem",
    fontWeight: "bold",
    marginBottom: 5,
    marginLeft: 7,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  fields: {
    width: "unset !important",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      "& > input": {
        caretColor: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.mode.background.input,
        border: "none !important",
        borderRadius: "12px !important",
        width: "56px !important",
        height: "56px !important",
        fontSize: "1.3125rem",
        color: theme.mode.text.heading,
        fontWeight: "bold",
        fontFamily: "inherit",
        [theme.breakpoints.down("sm")]: {
          width: "40px !important",
          height: "40px !important",
          borderRadius: "6px !important",
        },
      },
    },
  },
  errorFields: {
    color: "#ff3333",
    "& > div": {
      "& > input": {
        border: "2px solid #ff3333 !important",
        color: "#ff3333",
        caretColor: "#ff3333 !important",
      },
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(0, 3, 4, 3),
    gap: theme.spacing(3),
  },
  resendLabel: {
    color: theme.mode.text.formLabel,
    fontSize: "0.9375rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "baseline",
  },
  resendButton: {
    fontSize: "0.9375rem",
    fontWeight: 800,
    padding: 0,
    "&:disabled": {
      color: theme.mode.text.disabled,
    },
  },
  action: {
    alignSelf: "flex-end",
  },
  error: {
    fontSize: "0.6875rem",
    color: "#ff3333",
    fontWeight: "600",
  },
}));
