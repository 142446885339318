import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
  CircularProgress,
} from "@mui/material";

import { ErrorResponse } from "@apollo/client/link/error";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";

import { Button } from "@/common/components/button/Button";
import PrimaryButton from "@/common/components/PrimaryButton";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useTwoFactorStore } from "@/authentication/hooks/use-two-factor-store";

import useStyles from "./ValidatePhoneNumberDialog.styles";

type ValidatePhoneNumberDialogProps = {
  open: boolean;
  args?: {
    phone: string;
    country: string;
    password: string;
    isloggingIn: boolean;
    email: string;
    rememberMe: string;
    nextAttemptAt: Date | null;
    firebaseUid: string;
  } | null;
  onClose: () => void;
  onResend: () => void;
  onSubmit: (successful: boolean) => void;
};

export const ValidatePhoneNumberDialog = ({
  open,
  args,
  onClose,
  onResend,
  onSubmit,
}: ValidatePhoneNumberDialogProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { enable2Fa, enable2FaLoading } = useTwoFactorStore();

  const [readyToValidate, setReadyToValidate] = React.useState<boolean>(false);
  const [nextAttemptAt, setNextAttemptAt] = React.useState<Date>(args?.nextAttemptAt || new Date());
  const [nextAttemptTimeElapsed, setNextAttemptTimeElapsed] = React.useState<string>("01:00");
  const [nextAttemptTimeElapsedInt, setNextAttemptTimeElapsedInt] = React.useState<number>(-1);

  const { login, smLogin, createSmSessionLoading, loginLoading } = useAuthenticationStore();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      code: "",
    },
  });

  React.useEffect(() => {
    const subscription = watch(function (_value) {
      if (_value && (_value.code as string).length === 6) {
        setReadyToValidate(true);
      } else {
        setError("code", { type: "required" });
        setReadyToValidate(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (args?.nextAttemptAt) {
      setNextAttemptAt(new Date(args?.nextAttemptAt));
    }
  }, [args?.nextAttemptAt]);

  React.useEffect(() => {
    if (nextAttemptAt) {
      var x = setInterval(function () {
        var countDownDate = nextAttemptAt.getTime();

        var now = new Date().getTime();
        var distance = countDownDate - now;
        setNextAttemptTimeElapsedInt(distance);

        // Time calculations for days, hours, minutes and seconds
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
        if (seconds.length === 1) {
          seconds = "0" + seconds;
        }
        // Display the result in the element with id="demo"
        setNextAttemptTimeElapsed(`${minutes}:${seconds}`);

        // If the count down is finished
        if (distance < 0) {
          clearInterval(x);
        }
        return () => clearInterval(x);
      }, 1000);
    }
  }, [nextAttemptAt]);

  const { cx, classes } = useStyles();

  const handleResendCode = async () => {
    if (!args) return;
    if (!args?.isloggingIn) {
      await enable2Fa({
        variables: {
          phoneNumber: args?.phone,
          countryCode: args?.country,
          password: args?.password,
        },
      }).catch((error: ErrorResponse) => {
        if (error.graphQLErrors && error.graphQLErrors[0].extensions.next_attempt_at) {
          setNextAttemptAt(new Date(error.graphQLErrors[0].extensions.next_attempt_at));
        }
      });
    } else {
      if (args.firebaseUid) {
        await smLogin({ firebaseUid: args.firebaseUid, rememberMe: args.rememberMe }).catch(
          (error: ErrorResponse) => {
            if (error.graphQLErrors && error.graphQLErrors[0].extensions.next_attempt_at) {
              setNextAttemptAt(new Date(error.graphQLErrors[0].extensions.next_attempt_at));
            }
          },
        );
        return;
      }
      await login({
        email: args?.email,
        password: args?.password,
        rememberMe: args?.rememberMe,
      }).catch((error: ErrorResponse) => {
        if (error.graphQLErrors && error.graphQLErrors[0].extensions.next_attempt_at) {
          setNextAttemptAt(new Date(error.graphQLErrors[0].extensions.next_attempt_at));
        }
      });
    }
  };

  const handleCodeSubmit = async () => {
    const code = getValues("code");
    let successful = true;
    if (code) {
      if (args) {
        if (!args?.isloggingIn) {
          await enable2Fa({
            variables: {
              phoneNumber: args?.phone,
              countryCode: args?.country,
              password: args?.password,
              otpCode: code,
            },
          }).catch((error) => {
            console.log("Couldn't not confirm code. Try again later! Error:" + error.message);
            successful = false;
            if (error.message == "Invalid OTP code") {
              setError("code", { type: "required" });
              setReadyToValidate(false);
            }
          });
        } else {
          if (args.firebaseUid) {
            const response = await smLogin({
              rememberMe: args?.rememberMe,
              firebaseUid: args.firebaseUid,
              otpCode: code,
            }).catch((error) => {
              if (error.message === "Verify OTP code") {
                successful = false;
              }
              if (error.message == "Invalid OTP code") {
                console.log("invallididd");
                successful = false;
                setError("code", { type: "required" });
                setReadyToValidate(false);
              }
              return;
            });
            if (response && response.data?.createSmSession?.successful) {
              onSubmit(true);
            }
          } else {
            const response = await login({
              email: args?.email,
              password: args?.password,
              rememberMe: args?.rememberMe,
              otpCode: code,
            }).catch((error) => {
              if (error.message === "Verify OTP code") {
                successful = false;
              }
              if (error.message == "Invalid OTP code") {
                setError("code", { type: "required" });
                setReadyToValidate(false);
              }
              return;
            });
            if (response && response.data?.createSession?.successful) {
              onSubmit(true);
            }
          }
        }

        if (successful) {
          onSubmit(true);
        }
      }
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={() => onClose()}
      data-cy="ValidatePhoneNumberDialog"
      classes={{ paper: classes.dialogRoot }}>
      <DialogTitle className={classes.title}>
        {t("Settings.Security.TwoFactorAuth.VerifyPhoneNumber")}
        <IconButton classes={{ root: classes.headerCloseIcon }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(handleCodeSubmit)} noValidate>
        <DialogContent className={classes.content}>
          <span className="description">
            {t("Settings.Security.2FA.EnterPhoneNumberDescription")}
          </span>

          <Typography
            variant="body2"
            className={cx(classes.label, {
              [classes.errorFields]: errors.code && errors.code.type === "required",
            })}>
            {t("Settings.Security.2FA.VerificationPopup.VerificationCodeInputLabel")} *
          </Typography>
          <Controller
            name="code"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <ReactCodeInput
                autoFocus
                className={cx(classes.fields, {
                  [classes.errorFields]: errors.code && errors.code.type === "required",
                })}
                onChange={onChange}
              />
            )}
          />
          {errors.code && errors.code.type === "required" && (
            <span className={classes.error}>
              {t("Settings.Security.2FA.VerificationPopup.InvalidVerificationCodeError")}
            </span>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <span className={classes.resendLabel}>
            {t("Settings.Security.2FA.VerificationPopup.HaventReceivedCode")}
            &nbsp;
            <Button
              variant="inline-primary"
              className={classes.resendButton}
              disabled={nextAttemptTimeElapsedInt > 0}
              onClick={() => handleResendCode()}>
              {t("Settings.Security.TwoFactorAuth.ResendCode")}
            </Button>
            {nextAttemptTimeElapsedInt > 0 && <span>&nbsp;({nextAttemptTimeElapsed})</span>}
          </span>
          <PrimaryButton
            className={classes.action}
            type="submit"
            variant="contained"
            disabled={!isValid || !readyToValidate || enable2FaLoading || createSmSessionLoading}
            data-cy="ValidatePhoneNumberDialog__submit">
            {enable2FaLoading || loginLoading || createSmSessionLoading ? (
              <CircularProgress size={24} />
            ) : (
              t("Generic.Confirm")
            )}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
